import React from 'react';
import CursorState from './CursorState.js';
import PropTypes from 'prop-types';

/** MoveBox
 * warning: Needs a full area to move around in!
 * A box that can be moved around the screen using the mouse like an OS window.
 * handle      = The content that can be used to drag the box around.
 * content     = The content that cannot be used to drag the box around.
 * cursorState = A CursorState object that helps with getting the current cursor state.
 * id          = The HTML ID to give the overall box.
 */
export class MoveBox extends React.Component {
  static propTypes = {
    /** Cursor state object that tracks cursor state. */
    cursorState: PropTypes.instanceOf(CursorState),
    /** The id to give to the movebox. */
    id: PropTypes.string,
    /** Maybe you just want to make sure the style matches but don't want the function. */
    moveable: PropTypes.bool,
    /** Add another class to the movebox. */
    class: PropTypes.string
  }
  constructor(props) {
    super(props);
    this.state = {
      x: 0,
      y: 0,
      offsetX: 0,
      offsetY: 0,
      isHeld: false,
      startDrag: 0,
      animation: null,
      positionType: 'relative',
      userSelect: 'auto' //user select needs to be disabled while in motion or it can mess things up.
    };
  }

  componentDidMount() {
    const initialWidth = this.boxElement.offsetWidth;
    this.setState({ initialWidth });
  }

  /**
   * Control window by touch screen drag
   * @param {event} e - the touch event 
   */
  touchDown(e) {
    var offsetX = e.touches[0].clientX - e.currentTarget.parentElement.offsetLeft;
    var offsetY = e.touches[0].clientY - e.currentTarget.parentElement.offsetTop;
    this.setState({
      x: this.props.cursorState.x - offsetX,
      y: this.props.cursorState.y - offsetY,
      offsetX: offsetX,
      offsetY: offsetY,
      isHeld: true,
      startDrag: window.performance.now(),
      animation: window.requestAnimationFrame(() => this.onMove()),
    });
  }

  /**
   * Control window by mouse click
   * @param {event} e - the click event 
   */
  onDown(e) {
    var offsetX = this.props.cursorState.x - e.currentTarget.parentElement.offsetLeft;
    var offsetY = this.props.cursorState.y - e.currentTarget.parentElement.offsetTop;
    this.setState({
      x: this.props.cursorState.x - offsetX,
      y: this.props.cursorState.y - offsetY,
      offsetX: offsetX,
      offsetY: offsetY,
      isHeld: true,
      startDrag: window.performance.now(),
      animation: window.requestAnimationFrame(() => this.onMove()),
    });
  }

  /**
   * Adjusts while the user is moving the box, and monitors for move completion.
   */
  onMove() {
    this.state.positionType = 'absolute';
    this.state.userSelect = 'none';
    document.body.style.userSelect = 'none'; // Prevent text selection on the entire page
    if(this.state.isHeld && this.props.cursorState.isButtonDown) {
      this.moveToCursor();
      window.requestAnimationFrame(() => this.onMove());
    } else {
      this.state.userSelect = 'auto';
      document.body.style.userSelect = 'auto'; // Re-enable text selection on the entire page
      this.setState({
        isHeld: false
      });
    }
  }

  /**
   * Moves the box to the cursor location.
   */
  moveToCursor() {
    const maxAllowedDifference = 60; // Define the maximum allowed difference between frames
    const deltaX = Math.abs(this.props.cursorState.x - this.state.x - this.state.offsetX);
    const deltaY = Math.abs(this.props.cursorState.y - this.state.y - this.state.offsetY);

    if (deltaX > maxAllowedDifference || deltaY > maxAllowedDifference) {
      console.warn("Movement difference too large, releasing the box.");
      this.setState({ isHeld: false });
      return;
    }

    this.setState({
      x: this.props.cursorState.x - this.state.offsetX,
      y: this.props.cursorState.y - this.state.offsetY,
    });
  }

  render() {
    this.boxElement = (
      <div
        className={"moveBox " + this.props.class}
        id={this.props.id}
        ref={(el) => { this.boxElement = el; }}
        style={{
          left: this.state.x,
          top: this.state.y,
          position: this.state.positionType,
          minWidth: this.state.initialWidth ? `${this.state.initialWidth}px` : 'auto',
          maxWidth: this.state.initialWidth ? `${this.state.initialWidth}px` : 'auto'
        }}
      >
        <div 
          style={{position: "absolute", cursor: "pointer"}}
          className="moveBoxHandle"
          onMouseDown={(e) => this.props.moveable ? this.onDown(e) : undefined}
          onTouchStart={(e) => this.props.moveable ? this.touchDown(e) : undefined}
          onTouchMove={(e) => this.props.moveable ? this.onMove(e) : undefined}
        >
          {this.props.handle}
        </div>
        <div 
          className="moveBoxContent"
          style={{position: "relative", overflow: "auto", userSelect: this.state.userSelect}}
        >
          {this.props.content}
        </div>
      </div>
    );
    return this.boxElement;
  }
}
MoveBox.defaultProps = {
  moveable: true,
  class: ''
};
export default MoveBox;