import React from 'react';
import MoveBox from '../MoveBox.js';
import WebFire from '../WebFire.js';
import PropTypes from 'prop-types';
import CursorState from '../CursorState.js';
import AnimatedText from '../AnimatedText.js';
import Navigation from '../Navigation.js';

export class Home extends React.Component {
    static propTypes = {
    }
    constructor(props) {
        super(props);
        this.cursorState = new CursorState();
    }

    componentDidMount() {
        const isMobile = window.innerWidth <= 768;
        if (!isMobile) {
            var wf = new WebFire({targetId: 'testbox360'});
            wf.addFire();
        }

        var newEntry = new AnimatedText({
            text: `2025-03-02<br></br>I'm updating my site again! The food tester has been fixed, so it should work, and you can once again dine in peace. 
            It's been retrained using only the finest DuckDuckGo image searches and is ready to review your food. All the other sections have also been updated. 
            Thanks for checking out the site—drop me a line if you like it!`,
            secondsPerChar: 0.02,
            id: 'new',
        });
        newEntry.show(true, true);
    }

    render() {
        document.body.classList.add("scrollingBackground");
        const handle = <div> </div>;

        const oldContent1 = <div id='old1' className="animated_text_holder">
            <div id='old1' className="">
                I'm building a web page! Check back soon for great deals, TikTok dances, 
                scandelous celebrity gossip, natural cures that actually work, the news 
                <i>they</i> don't want you to know, gaming tips, koi farming information, 
                reboxing videos and much, much more!<p>In the meantime please excuse the fire.</p>
            </div>
        </div>;

        const oldContent2 = <div id='old1' className="animated_text_holder">
            <div id='old2' className="animated_text_visible">
            2022-10-05<br></br>
            Try out my new <a href='./food'>FOOD TESTER</a> app! Have you ever wondered if food 
            would taste good or bad before you eat it? Wonder no more, as <b>advanced neural 
            network AI</b> powered by <b>DEEP LEARNING</b> will let you know based only on a 
            picture! Never eat a bad meal again!
            </div>
        </div>;

        const oldContent3 = <div id='old1' className="animated_text_holder">
            <div id='old2' className="animated_text_visible">
            2023-03-20<br></br>Do you desire to test your piano skills with the <b>ULTIMATE CHELLANGE</b>? Chellenge yourself to the PIANO GAME with any midi file you have (remember midi files?... no?)! Or just chill out and relax while watching colourful vertical lines descend from the top of the screen in <a href='./piano'>PIANO GAME!</a>
            </div>
        </div>;

        const newContent = <div id='new' className="animated_text_holder">
            <div id='new' className="animated_text_visible"/>
        </div>;

        const isMobile = window.innerWidth <= 768;
        const mobileWidth = window.innerWidth - 40;

        const containerStyle = isMobile ? { display: "flex", flexDirection: "column-reverse", alignItems: "left", marginTop: "20px", padding: "0 20px" } : {};
        const boxStyle = isMobile ? { width: `${mobileWidth}px`, margin: "20px 0" } : {width: `650px`};

        return (
            <>
            <Navigation mode="full"></Navigation>
            <div className="content" style={containerStyle}>
                <div style={{ ...boxStyle, position: isMobile ? "relative" : "absolute", left: isMobile ? "0" : "480px", top: isMobile ? "0" : "850px", paddingTop: isMobile ? "10px" : "0" }}>
                    <MoveBox
                    handle={handle}
                    content={oldContent1}
                    cursorState={this.cursorState}
                    id='oldbox1'
                    class='oldbox' />
                </div>

                <div style={{ ...boxStyle, position: isMobile ? "relative" : "absolute", left: isMobile ? "0" : "320px", top: isMobile ? "0" : "750px", paddingTop: isMobile ? "10px" : "0" }}>
                    <MoveBox
                    handle={handle}
                    content={oldContent2}
                    cursorState={this.cursorState}
                    id='oldbox2'
                    class='oldbox' />
                </div>

                <div style={{ ...boxStyle, position: isMobile ? "relative" : "absolute", left: isMobile ? "0" : "400px", top: isMobile ? "0" : "650px", paddingTop: isMobile ? "10px" : "0" }}>
                    <MoveBox
                    handle={handle}
                    content={oldContent3}
                    cursorState={this.cursorState}
                    id='oldbox3'
                    class='oldbox' />
                </div>

                <div style={{ ...boxStyle, position: isMobile ? "relative" : "absolute", left: isMobile ? "0" : "390px", top: isMobile ? "0" : "200px", paddingTop: isMobile ? "10px" : "0" }}>
                    <MoveBox
                    handle={handle}
                    content={newContent}
                    cursorState={this.cursorState}
                    id='testbox360' />
                </div>
            </div>
            </>
        );
    }
}
export default Home;