import React from "react";
import ReactDOM from "react-dom/client";
import CursorState from './CursorState.js';
import FoodTest from './pages/FoodTest.js';
import ErrorPage from './pages/error-page.js';
import './styles/index.css';
import {
  createBrowserRouter,
  RouterProvider,
  Route,
} from "react-router-dom";
import Gallery from "./pages/Gallery.js";
import Movies from "./pages/Movies.js";
import About from "./pages/About.js";
import Home from './pages/Home.js';

// ========================================
var CURSORSTATE = new CursorState();

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
    errorElement: <ErrorPage />,
  },
  {
    path: "about",
    element: <About />,
    errorElement: <ErrorPage />,
  },
  {
    path: "food",
    element: <FoodTest />,
    errorElement: <ErrorPage />,
  },
  {
    path: "gallery",
    element: <Gallery />,
    errorElement: <ErrorPage />,
  },
  {
    path: "movies",
    element: <Movies />,
    errorElement: <ErrorPage />,
  },
]);

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);


