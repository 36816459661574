import React, { useState, useEffect, useRef } from 'react';
import AnimatedText from '../AnimatedText';
import '../styles/Gallery.css';
import Navigation from '../Navigation.js';

const Gallery = () => {
    const pictures = [
        ["adam_woody.webp", "2016-08-20 19:41:48", "Unknown", "Baby Adam and Woody"],
        ["babypull.webp", "2015-02-16 17:38:28", "Unknown", "Pulling my boy around in the snow. He's in there somewhere."],
        ["baby_raccoon.webp", "2012-09-24 12:14:47", "Unknown", "A cute baby raccoon"],
        ["birmingham.webp", "2019-11-09 10:43:53", "Unknown", "The streets of Birmingham, England. A nice city, some issues with poverty but an interesting history and some beautiful locations."],
        ["birminghamcan.webp", "2019-11-09 13:54:45", "Unknown", "More canals than venice! I heard that a lot. The canals were very nice, but not quite capitalized on the same way as venice. Maybe the gondola rides just weren't in my area."],
        ["birminghamuni.webp", "2019-11-07 09:52:37", "Unknown", "I was collaborating in research at Birmingham University. They say a broken clock is right twice a day but this one manages to be right at least 4 times a day. (apparently it couldn't be fixed because bats live there)"],
        ["birmingham_rain.webp", "2019-11-09 13:51:43", "Unknown", "The canals look nice in the rain. There weren't any gondolas but I did ride a boat."],
        ["BMI.webp", "2014-05-09 00:35:52", "Unknown", "In San Francisco, at a conference about technology relating to the brain. At the investor talk they told us to stop doing research into brain training apps like Lumosity and just invest and make money using marketing, or the whole house of cards would come tumbling down. It did not fill me with confidence in brain training apps or capitalism in general. They had a bunch of these simple EEG devices, and I got to see Jeri Ellsworth's Augmented Reality set she made for Valve that had been cancelled."],
        ["boat_fireworks.webp", "2023-08-20 20:44:49", "Unknown", "Fireworks and lights on the fishing boats at Crystal Crescent beach. It's a yearly event and very beautiful. Once it happened during an intense meteor shower. This was not that time."],
        ["boyandcat.webp", "2017-04-30 15:28:46", "West Pennant, Nova Scotia, Canada", "Adam playing in his sandbox at his grandparent's place while Jango rests nearby."],
        ["boyandcoi.webp", "2017-04-09 12:36:33", "West Pennant, Nova Scotia, Canada", "Adam watching our fish in the pond. Photo by Xiaofei."],
        ["boygramps.webp", "2020-07-03 20:58:45", "Unknown", "Adam and grandpa"],
        ["boyinsnow.webp", "2020-01-09 07:47:29", "Unknown", "I like this corner when cars aren't careening over the edge into the ditch."],
        ["boynfinlaw.webp", "2019-08-12 08:36:44", "West Pennant, Nova Scotia, Canada", "Adam and his grandparents from China when they came to visit."],
        ["boy_fishin.webp", "2025-03-15 10:32:46", "Unknown", "Adam fishing over the bridge. We caught something a couple of times but the fish seem to have figured us out."],
        ["buildingwgramps.webp", "2016-01-24 11:55:53", "West Pennant, Nova Scotia, Canada", "Adam having fun building a snow fort with his grandpa."],
        ["bunting.webp", "2019-11-01 14:35:12", "Unknown", "Bunting in Cambridge. Photo by my cousin Jeff."],
        ["cambridge_alley.webp", "2019-11-01 13:00:41", "Unknown", "An alley in Cambridge. I like alleys."],
        ["cambridge_computer_Museum.webp", "2019-11-01 15:16:22", "0.000000, 0.000000", "Adam learning about ancient computing techniques at the Cambridge Computer Museum."],
        ["cambridge_fam.webp", "2019-11-01 12:55:42", "Unknown", "Family in Cambridge. Photo by my cousin Jeff."],
        ["caught_fish.webp", "2024-07-05 08:36:35", "Unknown", "Adam caught his first fish! We cleaned it and ate it. He briefly thought about being a fisherman."],
        ["china_panda.webp", "2017-10-18 14:12:11", "Fuyang, Zhejiang, China", "I like the panda statues."],
        ["cloudline.webp", "2021-08-31 19:00:43", "Unknown", "Nature shot of a nice hard line between solid cloud and clear sky."],
        ["creepedout_baby2.webp", "2014-11-30 13:33:23", "Halifax, Nova Scotia, Canada", "Baby Adam looking slightly concerned."],
        ["creeped_out_baby.webp", "2014-10-25 11:00:48", "Unknown", "Me creeping out my baby."],
        ["crystalbeach.webp", "2011-04-25 01:32:42", "Unknown", "The rocky shore at Crystal Crescent beach."],
        ["dangerous_world.webp", "2016-08-05 17:26:30", "Unknown", "A boy excitedly running from his father towards a dangerous world. Check out that symbolism!"],
        ["dinglepark.webp", "2016-02-27 15:00:49", "Halifax, Nova Scotia, Canada", "Adam and grandpa at Dingle park."],
        ["DOUBLE_RAINBOW.webp", "2021-05-18 20:20:41", "Unknown", "A DOUBLE RAINBOW!"],
        ["DOUBLE_RAINBOW2.webp", "2021-05-18 20:33:10", "Unknown", "The sky shifted red on the double rainbow. Double rainbow double photos!"],
        ["downtownhfx_nocturne.webp", "2019-10-19 20:12:05", "0.000000, 0.000000", "Nocturne in downtown Halifax. It's a yearly nightime art festival, although this is just a shot of the city."],
        ["dragonboats.webp", "2010-06-16 14:47:09", "Unknown", "Dragonboat festival in Taiwan. I went with some friends and had a great time."],
        ["dragonboat_tents.webp", "2010-06-16 15:59:11", "Unknown", "There's a dragonboat festival in Halifax too, but it is much smaller than this one."],
        ["edinburgh _castle.webp", "2019-10-26 11:21:09", "Unknown", "Edinburgh castle. The AI in my text editor autocompleted that I didn't go in but I totally did it cost me like $300 quit lying AI."],
        ["edinburgh.webp", "2019-10-26 12:55:58", "Unknown", "A shot of Edinburgh FROM INSIDE THE CASTLE!!!"],
        ["england.webp", "2019-10-31 15:00:36", "London, England, United Kingdom", "Walking around London. There are about a million museums to see and all of them are great."],
        ["fabulous_dad.webp", "2019-10-26 09:58:47", "City of Edinburgh, Scotland, United Kingdom", "Me looking fabulous in Edinburgh pushing my boy around."],
        ["family_hike.webp", "2020-08-16 18:33:03", "Unknown", "The family on a hike."],
        ["fightin_baby.webp", "2014-11-30 12:40:49", "Halifax, Nova Scotia, Canada", "Baby Adam looking ready for a boxing match."],
        ["firepit.webp", "2014-06-21 09:38:03", "West Pennant, Nova Scotia, Canada", "A shot of my parent's fire pit overgrown in the summer."],
        ["fishtown.webp", "2014-07-27 13:58:35", "Unknown", "A different Nova Scotia fishing town."],
        ["foggy_moves.webp", "2021-11-13 17:04:02", "Unknown", "Adam and Xiaofei in the fog. I loved the lighting."],
        ["frog_lighthouse.webp", "2024-09-06 17:35:18", "Unknown", "A frog became lighthouse keeper in my koi pond."],
        ["frozen_bike.webp", "2014-03-30 14:22:40", "Unknown", "Halifax froze solid one winter. Here's a frozen bike."],
        ["frozen_grass.webp", "2022-02-05 13:55:37", "Unknown", "Every blade of grass was coated in ice."],
        ["frozen_halifax.webp", "2014-03-30 14:27:35", "Unknown", "A look out over the city one time everything got covered in a thick sheet of ice. Not the best picture, you can kind of see it on the trees."],
        ["funcat.webp", "2011-07-24 09:43:43", "Unknown", "Me and the cat just hanging out. Picture by Xiaofei."],
        ["funday.webp", "2014-07-27 16:11:46", "Unknown", "Family trip with grandparents to the Bay of Fundy."],
        ["GGB.webp", "2014-05-10 08:32:51", "Unknown","Jeff (Josh's cousin not mine), Josh and myself at the Golden Gate Bridge. Had a great time. The highlight for me was the redwoods but they don't really translate into photos."],
        ["golden_tire.webp", "2016-10-22 18:14:40", "Unknown", "A golden sky over Canadian Tire."],
        ["goosenap.webp", "2012-06-09 06:32:38", "Unknown", "Geese taking a nap."],
        ["goths_or_something.webp", "2019-08-04 13:37:01", "Halifax, Nova Scotia, Canada", "I've always been a big fan of goths. Or whatever, this is close enough I guess."],
        ["hfx_bridge.webp", "2018-09-16 12:26:39", "Dartmouth, Nova Scotia, Canada", "The bridge to Dartmouth."],
        ["hfx_pride.webp", "2012-07-29 02:38:36", "Unknown", "I think Halifax has the biggest pride parade in... I want to say Canada? I'm not looking it up, it's big though."],
        ["hiking.webp", "2021-05-24 15:28:01", "Unknown", "Hiking up a hill with Adam, grandma and grandpa."],
        ["house.webp", "2011-04-16 03:11:02", "Unknown", "Grandpa's house that he designed and did a ton of personal construction on."],
        ["hugwug.webp", "2021-12-26 15:14:17", "Unknown", "Adam posing with Hugwug at The Point."],
        ["ilikedthemmorewhentheydidntwanttodestroymycountry.webp", "2019-08-11 14:37:41", "Halifax, Nova Scotia, Canada", "A display of American military dominance during happier times."],
        ["inlaw.webp", "2016-07-03 11:19:16", "West Pennant, Nova Scotia, Canada", "Two grandmas posing together."],
        ["inlaw_kite.webp", "2016-07-03 11:39:35", "West Pennant, Nova Scotia, Canada", "Everyone but me posing. I need to get a tripod or something."],
        ["island.webp", "2019-03-31 10:33:29", "West Pennant, Nova Scotia, Canada", "A shot of the island off the point."],
        ["itsmeatbirmingham.webp", "2019-11-09 14:03:22", "Unknown", "A Birmingham selfie. I don't usually take selfies but I think I'm pretty cute here ;)"],
        ["Josh_Cali_SF.webp", "2014-05-08 00:10:24", "Unknown", "My old boss Josh in San Francisco. We had a lot of fun there during a conference."],
        ["kayak_trip.webp", "2022-09-03 19:28:45", "Unknown", "A kayak trip over the ocean to a nearby island."],
        ["lighthouse.webp", "2011-08-13 22:21:10", "Unknown", "Sambro lighthouse. It's the oldest lighthouse in North America."],
        ["lighthouse2.webp", "2011-08-14 00:16:54", "Unknown", "Another shot of Sambro lighthouse."],
        ["liminal_expo.webp", "2010-08-04 16:18:22", "Unknown", "Shanghai world expo, Korea Pavilion."],
        ["littledragonboats.webp", "2010-06-16 16:49:44", "Unknown", "Taiwan Dragonboat festival. Everyone is puttering about in little boats."],
        ["little_man.webp", "2015-12-30 08:42:17", "Unknown", "Little man out in the snow."],
        ["lookin_at_wolf.webp", "2016-05-28 10:50:54", "Unknown", "A baby and a wolf eyeing each other up."],
        ["luffy.webp", "2025-01-23 19:54:17", "Unknown", "My new cat Luffy enjoying the fireplace."],
        ["mario_pinball.webp", "2010-05-16 19:56:07", "Unknown", "Me playing Mario pinball in the Taipei night markets. Photo by Bruce Burns."],
        ["merely_a_pawn.webp", "2016-07-31 13:17:41", "Halifax, Nova Scotia, Canada", "Is it my boy's fate to be merely a pawn? I must educate him carefully to avoid it."],
        ["mikmaq2.webp", "2011-07-03 00:16:27", "Unknown", "I love the Mikmaq regalia, particularly the modernised use of fluorescent colors. I think it's important for traditions to change and adapt in positive ways without becoming stale or disappearing."],
        ["mikmaq3.webp", "2011-07-03 00:19:06", "Unknown", "Another shot of the Mikmaq ragalia."],
        ["momandbaby.webp", "2017-01-27 15:37:04", "West Pennant, Nova Scotia, Canada", "A mom and her baby."],
        ["montreal.webp", "2013-08-21 17:19:19", "Unknown", "Montreal is the best city in Canada by a wide margin, in my opinion. It's like it takes the best part of European and North American city design."],
        ["montreal_alleyway.webp", "2013-08-21 17:42:04", "Unknown", "An alleyway in Montreal. I like alleys."],
        ["montreal_biodome.webp", "2013-08-23 18:55:21", "Montreal, Quebec, Canada", "The biodome in Montreal seen from the nearby mount."],
        ["montreal_chess.webp", "2013-08-24 14:24:30", "Montreal, Quebec, Canada", "A chess game in a Montreal park."],
        ["montreal_graf.webp", "2013-08-21 17:35:14", "Unknown", "Graffiti in Montreal. There's some very cool graffiti there."],
        ["montreal_xf_lena.webp", "2013-08-22 11:57:49", "Unknown", "Xiaofei and Lena in Montreal."],
        ["moonlitforest.webp", "2022-10-09 19:16:40", "Unknown", "A forest in the moonlight."],
        ["northern_lights.webp", "2024-10-10 20:25:57", "Unknown", "The northern lights just outside my front door."],
        ["ocean_shed.webp", "2012-11-17 09:35:33", "Unknown", "An old fishing shed falling into the ocean. My dad wanted to preserve it but architecture does not automatically translate into boat design. A mistake he would try to make up for later."],
        ["patient_cat.webp", "2018-06-19 14:01:53", "West Pennant, Nova Scotia, Canada", "Jango was a patient cat."],
        ["pegggy.webp", "2013-11-11 14:58:09", "Peggys Cove Preservation Area, Nova Scotia, Canada", "Peggy's Cove."],
        ["phoenix1.webp", "2009-07-17 09:11:26", "Unknown", "It's Phoenix class! They just won a dance competition and teacher Tiffany is giving a medal to Jessie. The other kids are Nicole, Tippi, Angela, Hubert and I think Tommy with his back turned. Apparently our school won so much they were thinking of stopping the competitions. We spent like half our time preparing for shows and dances."],
        ["phoenix2.webp", "2010-05-15 11:19:23", "Unknown", "Jasmine from Phoenix class at a big trip to Flying Cow Ranch. Usually a real joker. One day she looked me dead in the eyes and said if I didn't think she was cool she would jump down the stairs and kill herself. I assured her she was very cool. I hope she's doing well and has more self confidence now."],
        ["phoenix3.webp", "2010-05-15 11:59:45", "Unknown", "Wendy, Angela and Betty. Good job Wendy."],
        ["phoenix4.webp", "2010-05-15 11:57:06", "Unknown", "Tommy was a good kid and a genius with amazing talent, but the rigid Taiwan education system wasn't doing him any favors and he had a rough time. He was crazy annoying to teach because he learned things instantly then spent the rest of the time distracting everyone. I always hope he pulled through and didn't get crushed by the system."],
        ["phoenix5_by_tommy.webp", "2010-05-15 12:39:15", "Unknown", "Tommy wanted to take a photo of me with my camera. I'm doing my best to look TERRIFYING."],
        ["pondskate.webp", "2022-01-23 12:41:26", "Unknown", "Skating on the pond in West Pennant."],
        ["pondskate2.webp", "2025-01-18 11:26:54", "Unknown", "Skating on the pond in West Pennant, the ice making a nice mirror."],
        ["pong.webp", "2019-11-01 15:37:37", "Unknown", "The computer museum in Cambridge. Jeff is schooling Adam in Pong and only lost once."],
        ["portrait.webp", "2006-09-02 22:36:18", "Unknown", "A street artist drew this portrait of me during Lunar Festival in Taiwan. He hesitated a bit at the nose and eyes, but I'm happy he just went for it with a big pointy nose. My favorite portrait."],
        ["proselytising_vehicle.webp", "2010-05-16 16:46:39", "Unknown", "I don't know what this thing is talking about but it's probably 100% correct. (I really don't know)"],
        ["qingdao.webp", "2010-07-29 12:35:00", "Uknown", "Qingdao city, where the beer comes from. Nearby where my wife lived. Here it is in the fog."],
        ["qingdao_coast.webp", "2010-07-29 14:37:01", "Unknown", "Qingdao is a coastal city, kind of like Halifax."],
        ["qingdao_dock.webp", "2010-07-29 16:18:04", "Unknown", "I love dock machinery."],
        ["redhill.webp", "2024-10-20 13:28:11", "Unknown", "I love the bright red colour of the plants."],
        ["red_sunrise.webp", "2022-11-01 07:41:35", "Unknown", "A vibrant red sunrise."],
        ["rip_tuzi.webp", "2020-04-30 12:25:15", "West Pennant, Nova Scotia, Canada", "Tuzi, the bunny we adopted. He loved being outside and seemed to move slow enough that it wasn't a problem. Then one day he booked it. Sorry Tuzi, we let you down."],
        ["rockyshore.webp", "2022-11-11 11:22:00", "Unknown", "The rocky shore at Crystal Crescent beach."],
        ["scotlandsheepfield.webp", "2019-10-27 15:17:52", "Unknown", "A field of sheep near the castle at Bridge Over the River Tweed, where I have family."],
        ["scottland.webp", "2019-10-27 13:17:52", "Unknown", "The town of Bridge Over the River Tweed. It's a nice small Scottish town."],
        ["seashore_hike.webp", "2020-12-27 11:31:02", "Unknown", "A hike along the shore, grandma with her grandson."],
        ["seashore_hike2.webp", "2020-12-27 11:19:39", "Unknown", "A cold day at the beach."],
        ["SF.webp", "2014-05-08 01:13:13", "Unknown", "San Francisco. The buildings are big."],
        ["SF_building.webp", "2014-05-09 11:50:32", "Unknown", "San Francisco has some big buildings."],
        ["SF_seals.webp", "2014-05-10 02:21:36", "Unknown", "Seals in San Francisco. The AI corrected me in the next sentence with \"I think they're sea lions.\""],
        ["SF_traincar.webp", "2014-05-09 09:12:49", "Unknown", "A train car in San Francisco."],
        ["SF_walk.webp", "2014-05-08 00:11:24", "Unknown", "San Francisco. I just wanted a shot of the street but this woman's pose in the center is absolutely perfect."],
        ["SF_windy.webp", "2014-05-10 11:59:39", "Unknown", "This photo sucks but you really had to be there. My boss's cousin Jeff said this radio tower was really cool at night so we went up there and it was crazy windy and we felt like we would be blown off the hill and the tower looked like the Eye of Sauron. You had to be there but I'm making you look at the picture anyway."],
        ["SF_wires.webp", "2014-05-08 00:14:37", "Unknown", "I just like wires and infrastructure."],
        ["Shanghai.webp", "2010-02-20 15:24:20", "Unknown", "Shanghai streets."],
        ["shanghai_bladerunneresque.webp", "2009-10-04 20:45:17", "Unknown", "Shanghai at night looking like a scene out of Blade Runner, with the giant screens and everything. I've often heard architects talk about Blade Runner so I assume whoever designed this knew exactly what they were doing."],
        ["shanghai_expo.webp", "2010-08-02 15:14:00", "Unknown", "It's me at the Shanghai Expo, photo by my wife Xiaofei."],
        ["shanghai_model.webp", "2010-02-20 16:12:40", "Unknown", "A model of Shanghai."],
        ["shanghai_night.webp", "2009-10-04 20:35:29", "Unknown", "Shanghai at night. Very futuristic. I'm kind of sad I never made it to Hong Kong while it was still the cyberpunk capital but Shanghai is nice too."],
        ["shanghai_streets.webp", "2009-10-05 19:48:00", "Unknown", "The streets of Shanghai."],
        ["shanghai_temple.webp", "2009-10-04 11:32:36", "Unknown", "A temple in the middle of Shanghai city. I love the juxtaposition."],
        ["shanghai_train.webp", "2010-07-20 09:32:06", "Unknown", "The trainyard, heading to my wife Xiaofei's old university. The best food in China is at the university cafeterias, just in general. It's really good."],
        ["shoretreeboy.webp", "2018-03-06 12:51:55", "West Pennant, Nova Scotia, Canada", "Adam sitting at the shore under a tree."],
        ["snakemarket_night.webp", "2010-05-16 18:09:18", "Unknown", "Leaving the snakemarket, the sun was hitting the buildings nicely. I loooved the night markets and went there almost every day."],
        ["snakemarket_temple.webp", "2010-05-16 16:57:53", "Unknown", "A temple near the Taipei snake market. I love that Taiwan has more classic Chinese culture like this, there were even outdoor puppet shows at some temples."],
        ["snowy_corner.webp", "2021-01-22 17:42:09", "Unknown", "The corner by my house."],
        ["snowy_sunset_fire.webp", "2024-12-23 17:17:54", "Unknown", "A snowy sunset by the fire pit."],
        ["sunset.webp", "2015-12-25 16:37:44", "Unknown", "Another sunset? I see the filename but I'm not going to bother looking up the picture. Enjoy!"],
        ["sunset_house.webp", "2020-10-04 18:59:23", "Unknown", "Grandparent's house in the sunset."],
        ["taipei101newyear.webp", "2010-01-01 00:01:43", "Unknown", "Happy New Year Taipei! I miss that <s>country</s> rebellious province (hey I got family in China I got to be careful give me a break)."],
        ["taipeiscience.webp", "2006-12-30 17:23:53", "Unknown", "The Taipei science museum. Great art on the banner."],
        ["taipei_back_street.webp", "2010-07-06 16:14:24", "Unknown", "Walking down the street in Taipei."],
        ["taipei_market.webp", "2010-05-16 17:11:37", "Unknown", "The Taipei night market. Always great to visit and always interesting food and fashion."],
        ["Taipei_night_temple.webp", "2010-05-16 20:42:24", "Unknown", "Temple near the night market at night. There were lots of old traditional looking buildings like this tucked away"],
        ["theodore.webp", "2011-07-02 02:04:33", "Unknown", "It's Theodore, the protagonist of the short lived Theodore Tugboat series."],
        ["theodore2.webp", "2013-08-05 20:21:53", "Unknown", "Theodore Tugboat in Halifax. Giving me a smile and a look."],
        ["the_fog.webp", "2021-11-13 17:09:46", "Unknown", "It's a mysterious world."],
        ["tideoutsunset.webp", "2018-12-25 16:22:36", "West Pennant, Nova Scotia, Canada", "A sunset along the shore when the tide is out. I like the patches of reflection."],
        ["tree_tuzi.webp", "2020-08-04 17:35:15", "Unknown", "Oh Tuzi, I didn't think you'd run away. He spent a lot of time outdoors."],
        ["turtle_rock.webp", "2010-07-17 18:27:02", "Unknown", "Turtle Rock in Taiwan. We had a fun day at the beach."],
        ["tuzi_outside.webp", "2020-06-17 18:08:31", "West Pennant, Nova Scotia, Canada", "It's my bunny Tuzi and cat Jango. Tuzi loved the outdoors but didn't get along with the cat."],
        ["walkingwmom.webp", "2016-01-17 13:42:58", "Unknown", "Walking with mom."],
        ["water_forest.webp", "2015-12-25 15:40:02", "Unknown", "A view of sun reflecting off the water in the forest."],
        ["west_snowy.webp", "2013-12-25 16:46:32", "West Pennant, Nova Scotia, Canada", "Lena and parents in snowy West Pennant."],
        ["whateverthatislandiscalled.webp", "2013-08-05 19:54:47", "Unknown", "The island between Dartmouth and Halifax. The AI autocompleted \"I think it's called George's Island.\" Sure, sounds good."],
        ["whitehouseobama.webp", "2006-08-26 15:50:49", "Unknown", "I found this weird building called 'Whitehouse Obama' in a back ally in Taipei. It had US flags, China flags, a bunch of political clippings, crosses. I don't know what their politics were but sounds good to me."],
        ["xf_contemplation.webp", "2014-06-21 09:43:21", "West Pennant, Nova Scotia, Canada", "Xiaofei looking for baby lobsters."],
        ["xf_meow.webp", "2014-07-12 19:23:04", "West Pennant, Nova Scotia, Canada", "Xiaofei sitting by the shore while Jango belts out his usual meows."],
        ["xiamen2.webp", "2005-05-20", "Unknown", "It's old 厦大, where I studied for one summer a long time ago. Back then cameras used film."],
        ["xiamen3.webp", "2005-05-20", "Unknown", "Some old guy called it \"the lamest beach art he's ever seen\" but I thought they were fine. The beach is right across from Taiwan."],
        ["xiamen_class.webp", "2005-06-20", "Unknown", "My class at 厦大. Actually I mostly knew Japanese people there. My wife says I'm not allowed to speak Chinese anymore because my accent is intensely annoying (when we met she said it was exotic and cool)."],
        ["yonghe_back2.webp", "2009-09-26 17:24:59", "Unknown", "A shot in the back alleys of Yonghe, near my apartment."],
        ["yonghe_backyard.webp", "2009-09-21 21:32:19", "Unknown", "The view out of my window in Yonghe. I lived just behind a gas station next to the MRT."],
        ["yonghe_plants.webp", "2009-09-26 17:21:26", "Unknown", "There were lots of plants growing in the back alleys of Yonghe."],
        ["zhucheng tower.webp", "2010-02-16 14:47:40", "Unknown", "A tower in Zhucheng."],
        ["zhucheng.webp", "2010-02-20 17:17:19", "Unknown", "Street art in ZhuCheng."],
        ["zhucheng_construction.webp", "2010-02-12 16:12:07", "Unknown", "A shot of ZhuCheng city. I like the harsh lighting."],
        ["zhucheng_countryside.webp", "2010-02-15 09:18:30", "Unknown", "A travel out to the countryside near Zhu Cheng, where my wife's family is from."],
        ["zhucheng_dino_museum.webp", "2010-02-16 15:20:40", "Unknown", "Back then they were just setting up the dinosaur museum. The gift shop was full of nothing but South Park toys and I always wanted to know why and if they knew what those were. I guess they were placeholders for the dino stuff they'd be getting."],
        ["zhuchen_dragon.webp", "2010-02-16 15:26:55", "Unknown", "A dragon in ZhuCheng."],
        ["zhuchen_reflective_bridge.webp", "2010-02-16 15:34:07", "Unknown", "I like how the ice reflected this rainbow bridge."],
        ["zhujiajiao.webp", "2009-10-05 17:14:40", "Unknown", "ZhuJiaJiao, a water town near Shanghai. I went there with my wife Xiaofei, it was very beautiful."],
        ["zhujiajiao_market.webp", "2009-10-05 14:26:11", "Unknown", "There were traditional looking markets in ZhuJiaJiao. You could also dress up in traditional garb but my wife refused."],
        ["zhujiajiao_sunset.webp", "2009-10-05 16:11:57", "Unknown", "A sunset in ZhuJiaJiao. On my way back to Shanghai."],
    ];

    const [currentIndex, setCurrentIndex] = useState(0);
    const [thumbnails, setThumbnails] = useState([]);
    const [displayOrder, setDisplayOrder] = useState([...Array(pictures.length).keys()]); // Array of indices
    const [isChronological, setIsChronological] = useState(false);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const descriptionRef = useRef(null);
    const thumbnailContainerRef = useRef(null);
    const animatedTextInstance = useRef(null);
    const mainImageRef = useRef(null);

    useEffect(() => {
        // Add gallery-body class to body when component mounts
        document.body.classList.add('gallery-body');
        handleRandomOrder();
        // Remove the class when component unmounts
        return () => {
            document.body.classList.remove('gallery-body');
        };
    }, []);

    useEffect(() => {
        // Initialize animated text
        if (descriptionRef.current && !animatedTextInstance.current) {
            animatedTextInstance.current = new AnimatedText({
                id: 'imageDescription',
                secondsPerChar: 0.02,
                text: pictures[displayOrder[currentIndex]][3],
                targetId: 'imageDescription'
            });
            animatedTextInstance.current.show(true, true);
        }

        // Generate random thumbnail positions
        generateThumbnailPositions();
    }, []);

    useEffect(() => {
        // Handle window resize
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);
        
        // Cleanup event listener
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        // Update description when current image changes
        if (animatedTextInstance.current) {
            animatedTextInstance.current.newText(pictures[displayOrder[currentIndex]][3]);
            animatedTextInstance.current.show(true, true);
        }
    }, [currentIndex, displayOrder]);

    const generateThumbnailPositions = () => {
        if (!thumbnailContainerRef.current) return;
        
        const containerWidth = thumbnailContainerRef.current.offsetWidth;
        const containerHeight = 1000; // Reduced height for more compact arrangement
        const thumbnailWidth = 120;
        const thumbnailHeight = 90;
        const margin = 10;
        
        const positions = pictures.map((pic, index) => {
            // Random position within bounds
            const left = Math.random() * (containerWidth - thumbnailWidth - margin * 2) + margin;
            const top = Math.random() * (containerHeight - thumbnailHeight - margin * 2) + margin;
            // Random rotation between -15 and 15 degrees (reduced from -20 to 20)
            const rotation = Math.random() * 30 - 15;
            
            return {
                id: index,
                filename: pic[0],
                left, 
                top,
                rotation,
                zIndex: index
            };
        });
        
        setThumbnails(positions);
    };

    const handleNext = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % displayOrder.length);
    };

    const handlePrevious = () => {
        setCurrentIndex((prevIndex) => (prevIndex - 1 + displayOrder.length) % displayOrder.length);
    };

    const handleThumbnailClick = (index) => {
        // Find the position of this image in the current display order
        const displayIndex = displayOrder.findIndex(i => i === index);
        if (displayIndex !== -1) {
            setCurrentIndex(displayIndex);
            
            // Scroll to the main image view
            if (mainImageRef.current) {
                mainImageRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
        }
    };

    const handleRandomOrder = () => {
        setIsChronological(false);
        // Fisher-Yates shuffle algorithm
        const newOrder = [...displayOrder];
        for (let i = newOrder.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [newOrder[i], newOrder[j]] = [newOrder[j], newOrder[i]];
        }
        setDisplayOrder(newOrder);
        setCurrentIndex(0);
    };

    const handleChronologicalOrder = () => {
        setIsChronological(true);
        // Sort by date (second element in each picture array)
        // Create pairs of [index, date] for sorting
        const indexedDates = pictures.map((pic, index) => [index, pic[1]]);
        
        // Sort by date
        indexedDates.sort((a, b) => {
            // Handle 'Unknown' dates - put them at the end
            if (a[1] === 'Unknown') return 1;
            if (b[1] === 'Unknown') return -1;
            return new Date(a[1]) - new Date(b[1]);
        });
        
        // Extract just the indices in chronological order
        const newOrder = indexedDates.map(pair => pair[0]);
        setDisplayOrder(newOrder);
        setCurrentIndex(0);
    };

    const handleReshuffleThumbnails = () => {
        generateThumbnailPositions();
    };

    // Add this function to handle clicking on the main image
    const handleMainImageClick = () => {
        // Get the current image filename
        const currentImage = pictures[displayOrder[currentIndex]][0];
        // Open the full-size image in a new tab
        window.open(`../photogallary/original/${currentImage}`, '_blank');
    };

    // Dynamic styles based on window width
    const galleryContainerStyle = {
        marginLeft: windowWidth < 1400 ? '0' : '200px'
    };

    const mainImageStyle = {
        maxWidth: windowWidth < 1400 ? '100%' : '1040px',
        width: windowWidth < 1400 ? '100%' : '1040px'
    };

    return (
        <>
        <Navigation></Navigation>
        <div className="gallery-container" style={galleryContainerStyle}>
            <div className="main-gallery-viewport">
                <div className="order-controls">
                    <button 
                        onClick={handleRandomOrder}
                        className={!isChronological ? "active" : ""}
                    >
                        Random
                    </button>
                    <button 
                        onClick={handleChronologicalOrder}
                        className={isChronological ? "active" : ""}
                    >
                        Chronological
                    </button>
                </div>
                
                <div className="main-image-container" ref={mainImageRef}>
                    <img 
                        src={`../photogallary/full/${pictures[displayOrder[currentIndex]][0]}`} 
                        alt={pictures[displayOrder[currentIndex]][3]}
                        className="main-image"
                        style={mainImageStyle}
                        onClick={handleMainImageClick}
                        title="Click to view full size image" // Add tooltip to indicate clickability
                    />
                </div>
                
                <div className="navigation-buttons">
                    <button onClick={handlePrevious}>Previous</button>
                    <button onClick={handleNext}>Next</button>
                </div>
                
                <div id="imageDescription" className="image-description" ref={descriptionRef}></div>
                
                <div className="reshuffle-container">
                    <button onClick={handleReshuffleThumbnails}>Reshuffle</button>
                </div>
                
                <div className="thumbnails-container" ref={thumbnailContainerRef}>
                    {thumbnails.map((thumb) => (
                        <div
                            key={thumb.id}
                            className="thumbnail"
                            style={{
                                left: `${thumb.left}px`,
                                top: `${thumb.top}px`,
                                transform: `rotate(${thumb.rotation}deg)`,
                                zIndex: thumb.zIndex
                            }}
                            onClick={() => handleThumbnailClick(thumb.id)}
                        >
                            <img 
                                src={`../photogallary/thumb/${thumb.filename}`} 
                                alt={`Thumbnail ${thumb.id}`}
                            />
                        </div>
                    ))}
                </div>
            </div>
        </div>
        </>
    );
};

export default Gallery;