/**
 * Connects to a nural network AI to evaluate pictures of food.
 * web input:
 * 
{
  "data": [
    str | Dict, // represents base64 url data, or (if tool == "sketch") a dict of image and mask base64 url data of 'img' Image component
  ]
}
 *
 * web output:
{
  "data": [
    Dict | None, // represents Object with key 'label' representing primary label, and key 'confidences' representing a list of label-confidence pairs of 'output' Label component
  ],
  "duration": (float) // number of seconds to run function call
}
 */
export class FoodEvaluator {
  constructor(args) {
    this.url = 'https://jacobkroeker.com/foodapp/'; // Use HTTPSing slash
  }

  /**
   * Ping the Hugging Face Space to ensure it is started.
   */
  async pingServer() {
  }

  /**
   * Post the image file to the server
   * @param {image} image
   * @param {function} callback
   */
  async sendImage(reader, callback) {
    try {
      const response = await fetch(this.url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          data: [reader.result], // Send base64 image data
        }),
      });
      const json = await response.json();
      callback(json);
    } catch (error) {
      callback(false);
      console.log(error);
    }
  }
}
export default FoodEvaluator;