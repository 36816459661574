import * as PIXI from 'pixi.js';
import image_spark from './styles/spark.png';

/**
 * Makes a little fire using PIXIjs and attaches it to an HTML element.
 * config
 * targetId = The ID of the element to have a webfire attached.
 */
export class WebFire { 
  constructor(args) {
    this.targetId = args.targetId;
    this.width = 450;
    this.height = 500;
    this.fireWidth = 70;
    this.fireOrigin = {x: (this.width - this.fireWidth)/2, y: this.height - 70};
    this.lastXPos = 0;
    this.lastYPos = 0;
    this.offsetFactor = 0.23;
    this.SPARK_COUNT = 1000;
  }

  /**
   * Adds fire to the associated element.
   */
  addFire() {
    this.app = new PIXI.Application({
      width: this.width,
      height: this.height,
      backgroundAlpha: 0,
    });
    document.getElementById(this.targetId).appendChild(this.app.view);
    this.app.view.style.cssText = "z-index:9999;position:absolute;top:-" + this.fireOrigin.y + "px;left:-" + this.fireOrigin.x + "px;pointer-events:none;"; // Set high z-index and disable pointer events
    this.app.view.classList.add("fireCanvas");
    this.lastXPos = document.getElementById(this.targetId).offsetLeft;
    this.lastYPos = document.getElementById(this.targetId).offsetTop;

    this.totalSprites = this.app.renderer instanceof PIXI.Renderer ? this.SPARK_COUNT : 50; //tone it down if they don't have webGL.

    const sprites = new PIXI.ParticleContainer(this.totalSprites, {
      scale: true,
      position: true,
      rotation: true,
      uvs: true,
      alpha: true,
    });
    this.app.stage.addChild(sprites);
    
    const sparks = [];
    for (let i = 0; i < this.totalSprites; i++) {
      const spark = PIXI.Sprite.from(image_spark);
      spark.anchor.set(0.5);
      spark.tint = 0xffeeaa;
      spark.x = this.width/2 + this.fireOrigin.x + Math.random() * 40;
      spark.y = this.fireOrigin.y;
      spark.scale.set(0.1 + Math.random() * 0.2);
      spark.scale.y = 0.1 + Math.random() * 0.6;
      spark.startTime = window.performance.now();
      spark.direction = -Math.PI;
      spark.speed = (1 + Math.random() * 2)/10;
      spark.offset = Math.random() * 100;
      spark.lifetime = 1000 * Math.random();
      spark.startTime = window.performance.now();
      sparks.push(spark);
      sprites.addChild(spark);
    }
    this.lastTime = window.performance.now();
    this.app.ticker.add(() => {
      this.incrementFire(sparks);
      this.checkFlammableElements();
    });
  }

  /**
   * Checks for flammable elements and adds fire if they touch the fire element.
   */
  checkFlammableElements() {
    const fireElement = document.getElementById(this.targetId).getBoundingClientRect();
    const elements = document.getElementsByClassName('flammable');
    for (let element of elements) {
      const rect = element.getBoundingClientRect();
      if (this.isTouching(fireElement, rect)) {
        this.targetId = element.id;
        this.createFire();
      }
    }
  }

  /**
   * Checks if two rectangles are touching.
   * @param {DOMRect} rect1 - The first rectangle.
   * @param {DOMRect} rect2 - The second rectangle.
   * @returns {boolean} - True if the rectangles are touching, false otherwise.
   */
  isTouching(rect1, rect2) {
    return !(rect1.right < rect2.left || 
             rect1.left > rect2.right || 
             rect1.bottom < rect2.top || 
             rect1.top > rect2.bottom);
  }

  /**
   * Creates fire effect on the target element.
   */
  createFire() {
    this.app = new PIXI.Application({
      width: this.width,
      height: this.height,
      backgroundAlpha: 0,
    });
    document.getElementById(this.targetId).appendChild(this.app.view);
    this.app.view.style.cssText = "z-index:9999;position:absolute;top:-" + this.fireOrigin.y + "px;left:-" + this.fireOrigin.x + "px;"; // Set high z-index
    this.app.view.classList.add("fireCanvas");
    this.lastXPos = document.getElementById(this.targetId).offsetLeft;
    this.lastYPos = document.getElementById(this.targetId).offsetTop;

    this.totalSprites = this.app.renderer instanceof PIXI.Renderer ? this.SPARK_COUNT : 50; //tone it down if they don't have webGL.

    const sprites = new PIXI.ParticleContainer(this.totalSprites, {
      scale: true,
      position: true,
      rotation: true,
      uvs: true,
      alpha: true,
    });
    this.app.stage.addChild(sprites);
    
    const sparks = [];
    for (let i = 0; i < this.totalSprites; i++) {
      const spark = PIXI.Sprite.from(image_spark);
      spark.anchor.set(0.5);
      spark.tint = 0xffeeaa;
      spark.x = this.width/2 + this.fireOrigin.x + Math.random() * 40;
      spark.y = this.fireOrigin.y;
      spark.scale.set(0.1 + Math.random() * 0.2);
      spark.scale.y = 0.1 + Math.random() * 0.6;
      spark.startTime = window.performance.now();
      spark.direction = -Math.PI;
      spark.speed = (1 + Math.random() * 2)/10;
      spark.offset = Math.random() * 100;
      spark.lifetime = 1000 * Math.random();
      spark.startTime = window.performance.now();
      sparks.push(spark);
      sprites.addChild(spark);
    }
    this.lastTime = window.performance.now();
    this.app.ticker.add(() => {
      this.incrementFire(sparks);
    });
  }

  /**
   * 
   * @param {[PIXI.Sprite]} sparks - the sparks of the fire. 
   */
  incrementFire(sparks) {
    let offset = this.getOffset();
    let rotation = this.getRotation();
    let deltaT = window.performance.now() - this.lastTime;
    this.lastTime = window.performance.now();
    for (let i = 0; i < this.totalSprites; i++) {
        const spark = sparks[i];
        let yChange = offset.y + Math.abs(Math.cos(spark.direction)) * spark.speed * deltaT;
        spark.y -= yChange * Math.cos(rotation) + offset.x * Math.sin(rotation);  
        spark.x += offset.x * Math.cos(rotation) - yChange * Math.sin(rotation);
        
        spark.tint = 0xffefeb - 0x00dd11 * (window.performance.now() - spark.startTime) / 1000;
        if ((window.performance.now() - spark.startTime)/spark.lifetime < .25) {
          spark.tint = 0xffefeb - 0x00dd77 * (window.performance.now() - spark.startTime) / 1000;
        } else if ((window.performance.now() - spark.startTime)/spark.lifetime < .60) {
          spark.tint = 0xff9943;
        } else if ((window.performance.now() - spark.startTime)/spark.lifetime < .70) {
          spark.tint = 0xd08594;
        } else {
          spark.tint = 0x999999;
        }

        if (rotation < 4.36 && rotation > 1.75) {
          this.resetSpark(spark);
        }
        // warp the sparks
        if (spark.lifetime < (window.performance.now() - spark.startTime)) {
          this.resetSpark(spark);
        }
      }
  }

  /**
   * Gets the associated elements offset for when it is moved around.
   * @returns {x: offset of x, y: offset of y}
   */
  getOffset() {
    var offsetY = document.getElementById(this.targetId).offsetTop - this.lastYPos;
    var offsetX = this.lastXPos - document.getElementById(this.targetId).offsetLeft;
    this.lastXPos = document.getElementById(this.targetId).offsetLeft;
    this.lastYPos = document.getElementById(this.targetId).offsetTop;
    return {x: offsetX * this.offsetFactor, y: offsetY * this.offsetFactor};
  }

  /**
   * @returns parent rotation in radians
   */
  getRotation() {
    let el = document.getElementById(this.targetId);
    if (el.style.cssText.indexOf('deg') < 2) {
      return 0;
    }
    return parseInt(el.style.cssText.substring(el.style.cssText.indexOf('deg') - 4, el.style.cssText.indexOf('deg')).replace(/\D/g,'')) * (Math.PI / 180);
  }

  /**
   * Resets a spark back to the base of the fire.
   * @param {PIXI.Sprite} spark - The spark to be reset to the start
   */
  resetSpark(spark) {
      spark.x = this.fireOrigin.x + Math.random() * 90;
      spark.y = this.fireOrigin.y + Math.random();
      spark.scale.set(0.1 + Math.random() * 0.2);
      spark.scale.y = 0.1 + Math.random() * 0.6;
      spark.lifetime = 200 + 600 * Math.random();
      spark.startTime = window.performance.now();
      spark.tint = 0xffefeb;
  }
}
export default WebFire;