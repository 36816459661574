import React from 'react';
import MoveBox from '../MoveBox.js';
import WebFire from '../WebFire.js';
import CursorState from '../CursorState.js';
import AnimatedText from '../AnimatedText.js';
import Navigation from '../Navigation.js';

export class About extends React.Component {
    static propTypes = {
    }
    constructor(props) {
        super(props);
        this.cursorState = new CursorState();
        this.state = {
            isMobile: window.innerWidth <= 768
        };
        this.handleResize = this.handleResize.bind(this);
    }

    componentDidMount() {
        window.addEventListener('resize', this.handleResize);
        const isMobile = window.innerWidth <= 768;
        if (!isMobile) {
            var wf = new WebFire({targetId: 'testbox360'});
            wf.addFire();
        }

        var newEntry = new AnimatedText({
            text: `I'm a full-stack and app developer with experience in a variety of object-oriented languages. 
            I create websites, games, applications, and assist with research. I also run the company <a href="https://matchboxenergy.com" target="_blank">Matchbox Energy</a>. 
            If you saw my site and thought, "I want this guy doing the energy modeling for my multi-million dollar building!"—you're in luck. I enjoy creating things and 
            use programming both as a career and a hobby.
            <br><br>
            I studied chemistry at Saint Mary's University, then switched to programming with a focus on machine learning and neural networks at Dalhousie University, 
            where I graduated with honours. I was a bit too early for the machine learning boom, so I primarily focus on web and app development, with freelancing gigs on the side. 
            I still have an interest in AI and machine learning and try to stay updated on the field. I'm also interested in society and how technology influences it, for better or worse.
            <br><br>
            I'm nostalgic for the early internet, when webpages were unique and an outlet for individual expression. There was more positivity, and a handful of companies didn't 
            control everything with algorithmic feeds. If you're looking for more bespoke websites from an artistic perspective, check out my sister's site <a href="https://www.lenakroeker.com" target="_blank">here</a>. Buy some art while you're at it!
            She is also a web developer and created her own site as well as Matchboxenergy, just so there's no confusion.   
            <br><br>
            I have a wonderful wife, Xiaofei, and a child, Adam, who I hope to train to survive the cyberpocalypse.<br>`,
            secondsPerChar: 0.002,
            id: 'new',
        });
        newEntry.show(true, true);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }

    handleResize() {
        const isMobile = window.innerWidth <= 768;
        if (isMobile !== this.state.isMobile) {
            this.setState({ isMobile });
        }
    }

    render() {
        const { isMobile } = this.state;
        document.body.classList.add("scrollingBackground");
        const handle = <div> </div>;

        const imageContent = (
            <div style={{ width: '250px', height: '250px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <img src='../images/shrek.jpg' alt="Profile" style={{ maxWidth: '250px', maxHeight: '250px' }} />
            </div>
        );

        const newContent = <div id='new' className="animated_text_holder">
            <div id='new' className="animated_text_visible"/>
        </div>;

        const mobileWidth = 250;

        const containerStyle = isMobile ? { display: "flex", flexDirection: "column-reverse", alignItems: "left", marginTop: "10px", padding: "0 20px" } : {};
        const boxStyle = isMobile ? { width: `${mobileWidth}px`, margin: "20px 0" } : {width: `800px`};
        const imageBoxStyle = isMobile 
            ? { ...boxStyle, margin: "20px 0" } 
            : { ...boxStyle, position: "absolute", left: "910px", top: "100px", width: "250px" };

        const navBuffer = 540;
        var contentMaxWidth = 800;

        if (window.innerWidth < navBuffer + contentMaxWidth) {
            contentMaxWidth = Math.max(window.innerWidth - navBuffer, 375);
        }

        return (
            <>
            <Navigation></Navigation>
            <div className="content" style={containerStyle}>
                <div style={{ ...imageBoxStyle, position: "absolute", left: (isMobile ? 80 : (contentMaxWidth + 100)) + "px", top: (isMobile ? 250 : 100) }}>
                    <MoveBox
                        handle={handle}
                        content={imageContent}
                        cursorState={this.cursorState}
                        id='imageBox' />
                </div>
                <div style={{ ...boxStyle, width: contentMaxWidth + "px", position: "absolute", maxWidth: "calc(100vw-1000px)", position: "absolute", left: isMobile ? "20px" : "390px", top: isMobile ? "560px" : "425px", paddingBottom: "200px", paddingTop: isMobile ? "10px" : "0" }}>
                    <MoveBox
                    handle={handle}
                    content={newContent}
                    cursorState={this.cursorState}
                    id='testbox360' />
                </div>
            </div>
            </>
        );
    }
}
export default About;