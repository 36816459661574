import React, { useState, useEffect, useRef } from 'react';
import Navigation from '../Navigation.js';
import '../styles/Movies.css';

const Movies = () => {
    // Define movies as tuples: [videoId, title, description]
    const movies = [
        ['rbwX7jZh8LI', 'Sirenhead Found Footage', 'A fun movie I made with my son. It recieved a FRIGHTNING amount of views. Dear lord. If you want views just namedrop some meme character. Made in a couple of days in time for Halloween.'],
        ['YcRIEDA2A9o', 'Lampsnake', 'Continuing our Halloween tradition of slapping together a movie in a couple of days, this one features award winning actor Woodrow Urich Smith. It just goes to prove that good writing and direction can really make a performance. Proof by contradiction.'],
        ['', 'Coming soon!', 'Oopse we missed last year. I was sick these things are hard as hell to make. I only get like 3 days. That counts me having to watch Blender tutorials for the first day.'],
    ];
    
    // State variables
    const [activeVideo, setActiveVideo] = useState(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const [videoProgress, setVideoProgress] = useState(0);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    
    // References
    const canvasRef = useRef(null);
    const contextRef = useRef(null);
    const playerRef = useRef(null);
    const animationFrameRef = useRef(null);
    const spectatorsRef = useRef([]);
    
    // Initialize on component mount
    useEffect(() => {
        document.body.classList.add('movies-body');
        
        // Handle window resize
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
            if (canvasRef.current) {
                const width = Math.min(windowWidth - 40, 800);
                canvasRef.current.width = width;
                initializeTheater();
            }
        };
        
        window.addEventListener('resize', handleResize);
        
        // Load YouTube API
        const tag = document.createElement('script');
        tag.src = 'https://www.youtube.com/iframe_api';
        const firstScriptTag = document.getElementsByTagName('script')[0];
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
        
        // Initialize canvas after a short delay to ensure DOM is ready
        const timer = setTimeout(() => {
            if (canvasRef.current) {
                initializeTheater();
            }
        }, 100);
        
        // Cleanup on unmount
        return () => {
            clearTimeout(timer);
            document.body.classList.remove('movies-body');
            window.removeEventListener('resize', handleResize);
            cancelAnimationFrame(animationFrameRef.current);
        };
    }, []);
    
    // Initialize theater canvas and draw initial scene
    const initializeTheater = () => {
        if (!canvasRef.current) return;
        
        const canvas = canvasRef.current;
        const context = canvas.getContext('2d');
        contextRef.current = context;
        
        // Calculate canvas dimensions
        const width = Math.min(windowWidth - 40, 800);
        canvas.width = width;
        canvas.height = 150;
        
        // Generate seats and spectators data
        generateTheaterScene(width);
        
        // Draw initial scene
        drawTheaterScene();
        
        // Start animation loop
        animateTheater();
    };
    
    // Generate data for theater scene (seats and spectators)
    const generateTheaterScene = (width) => {
        // Clear existing spectators
        spectatorsRef.current = [];
        
        // Two rows of seats with spectators
        // Two rows of seats with spectators
        for (let row = 0; row < 2; row++) {
            for (let col = 0; col < 10; col++) {
                // Seat position
                const seatX = col * (width / 10) + (width / 20);
                const seatY = row * 60 + 75; 
                
                // Add a spectator with 90% probability (nearly all seats filled)
                if (Math.random() > 0.1) {
                    // Create a more front-loaded distribution
                    // We want about half of people to leave before the movie is halfway through
                    
                    // Use a biased distribution to ensure more people leave in the first half
                    let leavingPoint;
                    
                    // 70% chance to assign a leaving point in the first half of the movie (0.0 - 0.5)
                    if (Math.random() < 0.6) {
                        // Front half - weighted toward middle of first half (0.2-0.4 range)
                        leavingPoint = 0.05 + (Math.random() * 0.45);
                    } else {
                        // Back half - more evenly distributed, there's a chance someone won't leave.
                        leavingPoint = 0.5 + (Math.random() * 0.55);
                    }
                    
                    // Add small random noise to avoid everyone leaving at the same times
                    leavingPoint += (Math.random() * 0.05) - 0.025;
                    
                    // Ensure leavingPoint stays within valid range
                    leavingPoint = Math.max(0.05, Math.min(0.95, leavingPoint));
                    
                    spectatorsRef.current.push({
                        x: 0,
                        y: 0,
                        seatX: seatX,
                        seatY: seatY,
                        visible: true,
                        leaving: false,
                        standingUp: false,
                        standUpProgress: 0,
                        leavingPoint: leavingPoint,
                        row: row,
                        col: col
                    });
                }
            }
        } 
        
        // Sort by leaving point to ensure evenly spaced departures
        spectatorsRef.current.sort((a, b) => a.leavingPoint - b.leavingPoint);
    };
    
    // Draw the theater scene on canvas
    const drawTheaterScene = () => {
        if (!contextRef.current || !canvasRef.current) return;
        
        const context = contextRef.current;
        const width = canvasRef.current.width;
        const height = canvasRef.current.height;
        
        // Clear canvas - IMPORTANT for animation
        context.clearRect(0, 0, width, height);
        
        // Draw background - now LIGHTER for better contrast with darker seats
        context.fillStyle = '#290000';
        context.fillRect(0, 0, width, height);
        
        // Draw screen indicator at the top
        context.fillStyle = 'rgba(255, 255, 255, 0.3)';
        context.fillRect(width * 0.1, 5, width * 0.8, 3);
        
        // Draw spectators FIRST (before seats)
        spectatorsRef.current.forEach(spectator => {
            if (spectator.visible) {
                // Draw spectator silhouette
                context.fillStyle = 'rgba(0, 0, 0, 0.9)';
                
                const posX = spectator.seatX + spectator.x;
                const posY = spectator.seatY + spectator.y;
                
                // Body
                context.fillRect(posX - 10, posY - 5, 20, 15);
                
                // Head
                context.beginPath();
                context.arc(posX, posY - 12, 8, 0, Math.PI * 2);
                context.fill();
            }
        });
        
        // Draw seats AFTER spectators so they appear on top - now DARKER colors
        for (let row = 0; row < 2; row++) {
            for (let col = 0; col < 10; col++) {
                // Calculate seat position
                const seatX = col * (width / 10) + (width / 20);
                const seatY = row * 60 + 75; // Moved down further from 45 to 75
                
                // Draw seat base - now darker red for better contrast with floor
                context.fillStyle = '#330000';
                context.fillRect(seatX - 20, seatY, 40, 15);
                
                // Draw seat backrest - now darker red for better visibility
                context.fillStyle = '#4d0000';
                context.beginPath();
                roundedRect(context, seatX - 20, seatY - 15, 40, 20, 5);
                context.fill();
            }
        }
        
        // Removed the LIVE indicator as requested
    };
    
    // Helper function to draw rounded rectangles
    const roundedRect = (ctx, x, y, width, height, radius) => {
        ctx.beginPath();
        ctx.moveTo(x + radius, y);
        ctx.lineTo(x + width - radius, y);
        ctx.arcTo(x + width, y, x + width, y + radius, radius);
        ctx.lineTo(x + width, y + height - radius);
        ctx.arcTo(x + width, y + height, x + width - radius, y + height, radius);
        ctx.lineTo(x + radius, y + height);
        ctx.arcTo(x, y + height, x, y + height - radius, radius);
        ctx.lineTo(x, y + radius);
        ctx.arcTo(x, y, x + radius, y, radius);
        ctx.closePath();
    };
    
    // Animation loop for theater
    const animateTheater = () => {
        // Always redraw with current spectator positions
        drawTheaterScene();
        
        // Continue animation loop
        animationFrameRef.current = requestAnimationFrame(animateTheater);
    };
    
    // Update spectators based on video progress
    const updateSpectators = (progress) => {
        if (!spectatorsRef.current.length || !isPlaying) return;
        
        // Only have spectators leave if there's a video playing
        // and we're at least 5 seconds into the movie
        const currentTime = playerRef.current ? playerRef.current.getCurrentTime() : 0;
        if (currentTime < 5) return;
        
        spectatorsRef.current.forEach(spectator => {
            // If it's time for this spectator to leave and they haven't started any leaving animation
            if (progress >= spectator.leavingPoint && !spectator.standingUp && !spectator.leaving) {
                spectator.standingUp = true;
            }
            
            // Handle standing up animation
            if (spectator.standingUp && !spectator.leaving) {
                // Move up slowly as if standing
                spectator.standUpProgress += 0.01; // Very slow standing up
                spectator.y = -spectator.standUpProgress * 12; // Move up to 12 pixels
                
                // After standing up animation completes, start leaving
                if (spectator.standUpProgress >= 1) {
                    spectator.standingUp = false;
                    spectator.leaving = true;
                }
            }
            
            // Animate departure if leaving
            if (spectator.leaving) {
                // Determine direction (leave to the closest edge)
                const direction = spectator.col < 5 ? -1 : 1;
                
                // Slow walking speed
                const speed = 0.5 + Math.random() * 0.3; // Very slow walking pace
                
                // Move spectator to the edge
                spectator.x += direction * speed;
                
                // If spectator has left the screen, hide them
                if ((direction < 0 && spectator.x + spectator.seatX < -50) || 
                    (direction > 0 && spectator.x + spectator.seatX > canvasRef.current.width + 50)) {
                    spectator.visible = false;
                }
            }
        });
    };
    
    // Reset spectators for a new video
    const resetSpectators = () => {
        if (!canvasRef.current) return;
        
        // Regenerate the theater scene
        generateTheaterScene(canvasRef.current.width);
    };
    
    // Create YouTube player when a video is selected
    useEffect(() => {
        if (!activeVideo || !window.YT || !window.YT.Player) return;
        
        if (playerRef.current) {
            playerRef.current.destroy();
        }
        
        // Create new player with selected video
        playerRef.current = new window.YT.Player('youtube-player', {
            height: '480',
            width: '853',
            videoId: activeVideo,
            playerVars: {
                'playsinline': 1,
                'rel': 0,
                'modestbranding': 1
            },
            events: {
                'onReady': onPlayerReady,
                'onStateChange': onPlayerStateChange
            }
        });
    }, [activeVideo]);
    
    // Update animation when video progress changes
    useEffect(() => {
        updateSpectators(videoProgress);
    }, [videoProgress]);
    
    // Handler for when player state changes
    const onPlayerStateChange = (event) => {
        const newIsPlaying = event.data === window.YT.PlayerState.PLAYING;
        setIsPlaying(newIsPlaying);
        
        if (newIsPlaying) {
            // Start tracking progress when video starts playing
            cancelAnimationFrame(animationFrameRef.current); // Cancel any existing animation
            trackProgress(); // Start tracking progress (which updates spectators)
        } else if (event.data === window.YT.PlayerState.ENDED) {
            setIsPlaying(false);
            setVideoProgress(0);
            resetSpectators();
        } else {
            // Pause tracking if video is paused
            cancelAnimationFrame(animationFrameRef.current);
            // Even though we've canceled the tracking, restart the drawing loop
            // so the spectators stay visible in their current positions
            animationFrameRef.current = requestAnimationFrame(animateTheater);
        }
    };
    
    // Handler for when player is ready
    const onPlayerReady = (event) => {
        event.target.playVideo();
    };
    
    // Track video progress
    const trackProgress = () => {
        if (playerRef.current) {
            const currentTime = playerRef.current.getCurrentTime();
            const duration = playerRef.current.getDuration();
            const progress = currentTime / duration;
            
            // Only update state if progress has changed significantly to avoid excessive re-renders
            if (Math.abs(progress - videoProgress) > 0.01) {
                setVideoProgress(progress);
            }
            
            // Always update spectators directly for smoother animation
            updateSpectators(progress);
            
            animationFrameRef.current = requestAnimationFrame(trackProgress);
        }
    };
    
    // Handle video selection - update to use the movie tuple
    const selectVideo = (videoId) => {
        setActiveVideo(videoId);
        setVideoProgress(0);
        resetSpectators();
    };
    
    // Get video thumbnails from YouTube
    const getVideoThumbnail = (videoId) => {
        return `https://img.youtube.com/vi/${videoId}/0.jpg`;
    };
    
    // Container style
    const containerStyle = {
        marginLeft: windowWidth < 1400 ? '0' : '200px'
    };
    
    return (
        <><Navigation></Navigation>
        <div className="movies-container" style={containerStyle}>
            {windowWidth >= 1400}
            
            <h1>Movie Theater</h1>
            
            <div className="movie-main-container">
                {activeVideo ? (
                    <div className="movie-player-container">
                        <div id="youtube-player"></div>
                    </div>
                ) : (
                    <div className="movie-welcome">
                        <h2>Select a Movie Below</h2>
                        <p>This is a virtual movie watching experience</p>
                    </div>
                )}
                
                {/* Always render the theater container and canvas */}
                <div className="theater-container">
                    <canvas ref={canvasRef} className="theater-canvas" />
                </div>
            </div>
            
            <div className="movie-selection-container">
                <h3>Movies</h3>
                <div className="movie-list">
                    {movies.map((movie) => {
                        const [videoId, title, description] = movie;
                        return (
                            <div 
                                key={videoId} 
                                className={`movie-item ${activeVideo === videoId ? 'active' : ''}`}
                                onClick={() => selectVideo(videoId)}
                            >
                                <div className="movie-thumbnail">
                                    <img 
                                        src={getVideoThumbnail(videoId)} 
                                        alt={`Thumbnail for ${title}`}
                                        onError={(e) => {
                                            e.target.onerror = null;
                                            e.target.src = 'https://placehold.co/480x360/333/white?text=Video';
                                        }}
                                    />
                                    <div className="play-button">▶</div>
                                </div>
                                <div className="movie-info">
                                    <h4>{title}</h4>
                                    <p className="movie-description">{description}</p>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
        </>
    );
};

export default Movies;
