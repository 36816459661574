import React from 'react';
import './styles/navigation.css';

export class Navigation extends React.Component {
    static propTypes = {
    }
    constructor(props) {
        super(props);
        this.state = {
            isMobile: window.innerWidth <= 768
        };
        this.handleResize = this.handleResize.bind(this);
    }

    componentDidMount() {
        if (this.props.mode !== "full") {
            window.addEventListener('resize', this.handleResize);
        }
    }

    componentWillUnmount() {
        if (this.props.mode !== "full") {
            window.removeEventListener('resize', this.handleResize);
        }
    }

    handleResize() {
        const isMobile = window.innerWidth <= 768;
        if (isMobile !== this.state.isMobile) {
            this.setState({ isMobile });
        }
    }

    render() {
        const { isMobile } = this.state;
        const { mode } = this.props;

        // Prevent collapsing if mode is "full"
        const shouldRenderMobile = mode !== "full" && isMobile;

        if (shouldRenderMobile) {
            return (
                <div className="miniNavigation moveBoxHandle moveBox">
                    <div className="navTitle">~ Navigation ~</div>
                    <ul className="navigationList">
                        <li><a href="./">HOME</a></li>
                    </ul>
                    <a target="_blank" className="email_link colorshift" href="mailto:me@jacobkroeker.com">Email me!</a>
                </div>
            );
        } else {
            return (
                <div className="navigation moveBoxHandle moveBox">
                    <div className="navTitle">~ Navigation ~</div>
                    <ul className="navigationList">
                        <li><a href="./">HOME</a></li>
                        <li><a href="./about">ABOUT</a></li>
                        <li><a href="./gallery">GALLERY</a></li>
                        <li><a href="./movies">MOVIES</a></li>
                        <li><a href="./food">FOOD TESTER</a></li>
                        <li><a href="./piano">PIANO GAME</a></li>
                    </ul>
                    <a target="_blank" className="email_link colorshift" href="mailto:me@jacobkroeker.com">Email me!</a>
                </div>
            );
        }
    }
}
export default Navigation;